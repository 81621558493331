import React from 'react';

function WhyLaunchWithUs() {
  const customStyle = {
    color: 'var(--primary-color5)', // Applying custom color
  };
  return (
    <section className="tf-blog-detail">
      <div className="tf-container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="detail-inner">
              <div className="content-top">
                <h4 className="title">Why launch with us</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="card card-border-bottom">
              <h5 className="card-title">Raise funds publicly</h5>
              <p style={customStyle}>Access a global reach of dedicated backers in a transparent and decentralized setting.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <h5 className="card-title">Build a community</h5>
              <p style={customStyle}>Grow and nurture your most loyal supporters from the very beginning. They’ve got your back!</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
              <h5 className="card-title">Decrease risk</h5>
              <p style={customStyle}>Take full control of your fundraising and leverage ANTS’s experience and community.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyLaunchWithUs;

