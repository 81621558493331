import React, { useState,useEffect } from 'react';
import { Modal } from 'react-bootstrap';

function StackModel({ show, onHide, onConfirm }) {
    const [amount, setAmount] = useState('');
    useEffect(() => {
        if (show) setAmount("");
      }, [show]);
    
    const handleAmountChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        setAmount(inputValue < 0 ? 0 : inputValue);
    };

    const handleContinue = () => {
        if (!amount) {
          alert("Please enter a valid amount");
          return;
        }
        onConfirm(amount);  
      };
    

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton />
            <div className="modal-body space-y-20 pd-40">
                <h3>Stake Here</h3>
                <input
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    className="form-control mb-5"
                    placeholder="Enter amount here in dollar"
                />
                <button
                    className="button-popup w-100"
                    onClick={handleContinue}
                >
                    Continue
                </button>
            </div>
        </Modal>
    );
}

export default StackModel;
