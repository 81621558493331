import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import icon1 from '../assets/images/svg/icon-create-5.svg';
import icon2 from '../assets/images/svg/icon-create-6.svg';
import icon3 from '../assets/images/svg/icon-create-3.svg';
import icon4 from '../assets/images/svg/icon-create-7.svg';
import icon5 from '../assets/images/svg/icon-create-8.svg';
import icon6 from '../assets/images/svg/icon-create-9.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { truncate } from 'lodash';
import { Helmet } from 'react-helmet';

HelpCenter.propTypes = {};

function HelpCenter(props) {
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const [seoData, setSeoData] = useState([]);

  const [expandedItems, setExpandedItems] = useState([]);

  const toggleExpand = (id) => {
    if (expandedItems.includes(id)) {
      setExpandedItems(expandedItems.filter((item) => item !== id));
    } else {
      setExpandedItems([...expandedItems, id]);
    }
  };

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/howworkstitle`)
      .then((res) => {
        setdata(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${global.backendUrl}/howworkssection`)
      .then((res) => {
        setdata2(res?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`${global.backendUrl}/howItWorksSeo`)
      .then((res) => {
        setSeoData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
console.log('data2',data2)
  return (
    <div>
      {seoData[0] && (
        <Helmet>
          <title>{seoData[0]?.seo_how_it_work_meta_title}</title>

          <meta name="description" content={seoData[0]?.seo_how_it_work_meta_description} />
          <meta name="keywords" content={JSON.parse(seoData[0]?.seo_how_it_work_meta_tags)} />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={seoData[0]?.seo_how_it_work_meta_title} />
          <meta property="og:description" content={seoData[0]?.seo_how_it_work_meta_description} />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.seo_how_it_work_meta_image}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={seoData[0]?.seo_how_it_work_meta_title} />
          <meta name="twitter:title" content={seoData[0]?.seo_how_it_work_meta_title} />
          <meta
            name="twitter:description"
            content={seoData[0]?.seo_how_it_work_meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.seo_how_it_work_meta_image}`}
          />
        </Helmet>
      )}
      <section className="tf-page-title style-2">
        <div className="tf-container">
          {/* <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/">Homes</Link>
                </li>
                <li>
                  <Link to="#">Pages</Link>
                </li>
                <li>How It Works</li>
              </ul>
            </div>
          </div> */}
        </div>
      </section>

      <section className="tf-help-center">
        <div className="tf-container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              {/* <div className="tf-heading style-5">
                                <h4 className="heading">How Can We Help You?</h4>
                                <p className="sub-heading">Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Laborum Obcaecati Dignissimos Quae Quo Ad Iste Ipsum Officiis Deleniti Asperiores Sit. </p>
                            </div> */}

              {/* <form action="#" className="help-center-form">
                                <input type="text" placeholder="Enter Question..." required="" />
                                <button><i className="fas fa-search"></i></button>
                            </form> */}
              <p 
                className="text-center"
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: '#3649E9',
                }}
              >
                {data[0]?.howworks_tag}
              </p>
              <h4 className="heading-help tf-heading style-5">
                {data[0]?.howworks_title}
              </h4>

              <div className="tf-create-wrap ">
                {data2.map((item) => {
                  return(
                    <div className="col-md-4 my-2">
                  <div key={item.howworks_id} className="tf-create style-2">
                    <div className="icon">
                      <img
                        src={`${global.imageURL}/nodeassets/${item.howworks_image}`}
                        alt="ANTS"
                      />
                    </div>
                    <h6 className="title">{item.howworks_title}</h6>
                    {/* <p className="content" style={{ textAlign: 'start' }}>
                      {expandedItems.includes(item.id)
                        ? item.howworks_text
                        : truncate(item.howworks_text, { length: 200 })}
                    </p> */}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: expandedItems.includes(item.howworks_id
)
                        ? item.howworks_text
                        : truncate(item.howworks_text, { length: 200 }),
                      }}
                    />
                    <div className="button">
                      <div
                        className="tf-button"
                        onClick={() => toggleExpand(item.howworks_id
)}
                      >
                        {expandedItems.includes(item.howworks_id
)
                          ? 'View less'
                          : 'View more'}
                        <i className="fal fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                  </div>
                  )
})}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HelpCenter;
{
  /* <div className="tf-create-wrap">
{
    data2.map((howworks_id
x,i) => (
        <div key={idx.id} className="tf-create style-2">
            <div className="icon">
                <img src={'https://binaseareact.vercel.app/static/media/icon-create-5.a239fe63e633e5bcaf9da315c8bbb3c0.svg'} alt="ANTS" />
            </div>
            <h6 className="title">{idx.howworks_title}</h6>
            <p className="content" style={{textAlign:'start'}}>{truncate(idx.howworks_text, { length: 200 })}</p>
            <div className="button">
                <div className="tf-button">View more<i className="fal fa-long-arrow-right"></i></div>
            </div>
        </div>
    ))
}
</div> */
}
