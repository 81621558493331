import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageTitle from '../components/pagetitle/PageTitle';
import { Link } from 'react-router-dom';
import img from '../assets/images/background/thumb-pagetitle.jpg';
import { Helmet } from 'react-helmet';
import { WebsiteUrl } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
UserProfile.propTypes = {};

function UserProfile(props) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [projects, setprojects] = useState([]);
  const [userImg, setuserImg] = useState('');
  const [coverImg, setcoverImg] = useState('');

  const [fb, setfb] = useState('');
  const [twitter, settwitter] = useState('');
  const [discord, setdiscord] = useState('');
  const [linkedin, setlinkedin] = useState('');
  const [medium, setmedium] = useState('');
  const [reddit, setreddit] = useState('');

  const dummyImage =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD116U9ZCk8bEaanCeB5rSCC2uqY5Ka_2_EA&usqp=CAU';

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.name == slug.replace(/-/g, ' ')
        );
        setdata(filteredData);
        setuserImg(filteredData[0]?.image);
        setcoverImg(filteredData[0]?.cover_img);
        setfb(filteredData[0]?.link_fb);
        settwitter(filteredData[0]?.link_twitter);
        setdiscord(filteredData[0]?.link_discord);
        setlinkedin(filteredData[0]?.link_linkedin);
        setmedium(filteredData[0]?.link_medium);
        setreddit(filteredData[0]?.link_reddit);

        axios
          .get(`${global.backendUrl}/project/get`)
          .then((res) => {
            const filteredData2 = res.data.filter(
              (t) => t.uid == filteredData[0]?.uid
            );
            setprojects(filteredData2);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {data[0] && (
        <Helmet>
          <title>{data[0]?.name} - ANTS Protocol</title>

          <link
            rel="canonical"
            href={`${WebsiteUrl}/user-profile/${data[0]?.name.replace(
              /\s+/g,
              '-'
            )}`}
          />
          <meta name="robots" content="index,follow" />
          <meta
            property="og:title"
            content={data[0]?.name.replace(/\s+/g, '-')}
          />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${data[0]?.image}`}
          />
          <meta property="og:url" content={`${WebsiteUrl}`} />
          <meta
            property="og:url"
            content={`${WebsiteUrl}/user-profile/${data[0]?.name.replace(
              /\s+/g,
              '-'
            )}`}
          />
          <meta property="og:type" content="website" />
          <meta
            name="twitter:card"
            content={data[0]?.name.replace(/\s+/g, '-')}
          />
          <meta
            name="twitter:title"
            content={data[0]?.name.replace(/\s+/g, '-')}
          />
          <meta
            name="twitter:image"
            content={`${WebsiteUrl}/nodeassets/${data[0]?.image}`}
          />
        </Helmet>
      )}
      <section class="tf-page-title ">
        <div class="tf-container">
          <div class="row">
            <div class="col-md-12">
              <ul class="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Profile</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="thumb-pagetitle w-100">
              <img
                className="w-100"
                style={{ objectFit: 'fill' }}
                src={
                  coverImg ? `${global.imageURL}/nodeassets/${coverImg}` : img
                }
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="tf-dashboard tf-tab">
        <div className="tf-container">
          <Tabs className="dashboard-filter">
            <div className="row ">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <div className="dashboard-user">
                  <div className="dashboard-infor">
                    <div className="avatar">
                      <img
                        src={
                          userImg
                            ? `${global.imageURL}/nodeassets/${userImg}`
                            : dummyImage
                        }
                        alt="images"
                        style={{ height: '120px' }}
                      />
                    </div>
                    <div className="name">{data[0]?.name}</div>
                    <ul className="social-item">
                      {fb && (
                        <li>
                          <a href={fb} target="_blank">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      )}
                      {twitter && (
                        <li>
                          <a href={twitter} target="_blank">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      )}
                      {discord && (
                        <li>
                          <a href={discord} target="_blank">
                            <i className="fab fa-discord"></i>
                          </a>
                        </li>
                      )}
                      {linkedin && (
                        <li>
                          <a href={linkedin} target="_blank">
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      )}
                      {medium && (
                        <li>
                          <a href={medium} target="_blank">
                            <i className="fab fa-medium"></i>
                          </a>
                        </li>
                      )}
                      {reddit && (
                        <li>
                          <a href={reddit} target="_blank">
                            <i className="fab fa-reddit"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <TabList className="filter-menuu menu-tab">
                    <Tab>
                      <a>
                        {' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        Projects
                      </a>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 overflow-table">
                <div className="dashboard-content inventory content-tab">
                  <TabPanel>
                    <div className="inner-content inventory">
                      <h4 className="title-dashboard">
                        <div>Projects</div>
                      </h4>
                      <div className="table-ranking ">
                        {projects.length !== 0
                          ? projects?.map((t, i) => (
                              <div className="content-ranking">
                                <div className="col-rankingg">
                                  <div className="image">
                                    <img
                                      src={`${global.imageURL}/nodeassets/${t.project_iconImage}`}
                                      style={{ width: '100%', height: '60px' }}
                                      alt="ANTS"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-rankingg"
                                  onClick={() =>
                                    navigate(
                                      `/project-detail/${t.project_slug}`
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  {t.project_name}
                                </div>
                                <div className="col-rankingg coin">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="22"
                                    viewBox="0 0 23 22"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.7619 0V8.13257L5.74624 10.8216L11.7619 8.13418V15.1949L4.89084 11.204L4.89062 11.2041L4.8907 11.2039L4.89062 11.2038L4.89077 11.2038L11.7619 0ZM11.7637 0L18.6357 11.2038L18.6359 11.2038L18.6359 11.2039L18.6359 11.2041L18.6357 11.204L11.7637 15.1949V8.13418L17.7802 10.8216L11.7637 8.13257V0ZM11.7626 16.4746V22.0005L4.88672 12.4844L11.7626 16.4746ZM11.7637 22.0005V16.4736L18.6359 12.4844L11.7637 22.0005Z"
                                      fill="#6B8CEF"
                                    />
                                  </svg>
                                  Ethereum
                                </div>
                                <div className="col-rankingg">0.45 Flow</div>
                                <div className="col-rankingg">4,915</div>
                                <div className="col-rankingg nofication">
                                  {' '}
                                  <i className="fas fa-circle"></i>{' '}
                                  {t.project_status}
                                </div>
                                <div className="col-rankingg dot">
                                  <Link to="#">
                                    <i className="fas fa-ellipsis-h"></i>
                                  </Link>
                                </div>
                              </div>
                            ))
                          : 'This User yet not created a Project'}
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </section>
    </div>
  );
}

export default UserProfile;
