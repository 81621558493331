import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useLocation } from "react-router-dom";
import img from "../assets/images/background/thumb-pagetitle.jpg";

import icon1 from "../assets/images/svg/icon-wallet-1.svg";
import icon2 from "../assets/images/svg/icon-wallet-2.svg";
import icon3 from "../assets/images/svg/icon-wallet-3.svg";
import icon4 from "../assets/images/svg/icon-wallet-4.svg";
import icon5 from "../assets/images/svg/icon-wallet-5.svg";
import icon6 from "../assets/images/svg/icon-wallet-6.svg";
import icon7 from "../assets/images/svg/icon-wallet-7.svg";
import icon8 from "../assets/images/svg/icon-wallet-8.svg";
import { bloguser, sellerimg } from "../utils/Assets";
import AccountSetting from "./components/AccountSetting";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDashboard } from "../hooks/dashboard";
import { useStateContext } from "../components/context/AppContext";
Dashboard.propTypes = {};

function Dashboard(props) {
  const { isAuthenticated,saleData,proposalData  } = useStateContext();
  const navigate = useNavigate();
  // const { saleData, setSaleData } = useStateContext();
  const [linkClick, setLinkClick] = useState(false);
  const [productId, setProductId] = useState(0);
  const [data, setdata] = useState([]);
  const [projects, setprojects] = useState([]);
  const [userImg, setuserImg] = useState("");
  const [coverImg, setcoverImg] = useState("");
  const [affiliatedUsers, setAffiliatedUsers] = useState([]);

  const [fb, setfb] = useState("");
  const [twitter, settwitter] = useState("");
  const [discord, setdiscord] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [medium, setmedium] = useState("");
  const [reddit, setreddit] = useState("");

  const [purchasedTickets, setPurchasedTickets] = useState([]);
  const [seoData, setSeoData] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);
  const location =useLocation()
  const {
    dataAPI,
    getData,
    userUid,
    filteredNotifications,
    saleAPI
  } = useDashboard();

  const dummyImage =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD116U9ZCk8bEaanCeB5rSCC2uqY5Ka_2_EA&usqp=CAU";

  useEffect(() => {
    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);
    console.log('users',parsedInfo.uid)

    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.uid == parsedInfo?.uid
        );
  
        setdata(filteredData);
        setuserImg(filteredData[0]?.image);
        setcoverImg(filteredData[0]?.cover_img);
        setfb(filteredData[0]?.link_fb);
        settwitter(filteredData[0]?.link_twitter);
        setdiscord(filteredData[0]?.link_discord);
        setlinkedin(filteredData[0]?.link_linkedin);
        setmedium(filteredData[0]?.link_medium);
        setreddit(filteredData[0]?.link_reddit);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/dashboard-projects/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.uid == parsedInfo?.uid
        );
        setprojects(filteredData);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/tickets/purchase/${parsedInfo?.uid}`)
      .then((res) => {
        setPurchasedTickets(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${global.backendUrl}/profileSeo`)
      .then((res) => {
        setSeoData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchData = async () => {
    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);


    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        const filteredData = response.data.filter(
          (t) => t.uid == parsedInfo?.uid
        );
        setdata(filteredData);
        setuserImg(filteredData[0]?.image);
        setcoverImg(filteredData[0]?.cover_img);
        setfb(filteredData[0]?.link_fb);
        settwitter(filteredData[0]?.link_twitter);
        setdiscord(filteredData[0]?.link_discord);
        setlinkedin(filteredData[0]?.link_linkedin);
        setmedium(filteredData[0]?.link_medium);
        setreddit(filteredData[0]?.link_reddit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    axios.delete(`${global.backendUrl}/project/delete/${id}`);
    const filterArr = projects.filter((val) => val.project_id !== id);
    setprojects(filterArr);
  };


  useEffect(() => {
    const stateTabIndex = location.state?.tabIndex; 
    const savedTabIndex = localStorage.getItem("dashboardTabIndex");
    setTabIndex(
      stateTabIndex !== undefined ? stateTabIndex : savedTabIndex ? parseInt(savedTabIndex, 10) : 1
    );
  }, [location.state]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    localStorage.setItem("dashboardTabIndex", index);
  };
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  const filterdSale=saleAPI.filter((sale)=>sale.private_sale_status === "Active")
  const formattedSaleData = filterdSale.map((data) => ({
    ...data,
    private_sale_start_time_formatted: formatDateTime(
      data.private_sale_start_time
    ),
    private_sale_end_time_formatted: formatDateTime(data.private_sale_end_time),
  }));

  const handleNavigateToPrivateSale = () => {
    if (saleData) {
      navigate("/private-sale-module", { state: saleData });
    }
  };
 

  useEffect(() => {
    const accountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(accountInfo);

    if (parsedInfo?.uid) {
      axios
        .get(`http://localhost:5000/users/affiliates/${parsedInfo.uid}`)
        .then((response) => {
          setAffiliatedUsers(response.data);
        })
        .catch((error) => {
          console.error("Failed to fetch affiliates:", error);
        });
    }
  }, []);
  
  useEffect(() => {
    getData();
  }, []);
 
  const handleShareLink = () => {
    const AccountInfo = localStorage.getItem("antsInformation");
    const parsedInfo = JSON.parse(AccountInfo);
  
    const affiliateLink = `${window.location.origin}/login?affiliate_id=${parsedInfo?.uid}`;
    navigator.clipboard
      .writeText(affiliateLink)
      .then(() => {
        alert("Affiliate link copied to clipboard!");
        // axios.post(`http://localhost:5000/users/update/affiliate`, {
        //   uid: parsedInfo?.uid,
        //   affiliate_id: parsedInfo?.uid,  
        // })
        // .then((response) => {
        //   console.log(response.data);
        // })
        // .catch((error) => {
        //   console.error("Failed to update affiliated ID:", error);
        // });
      })
      .catch((err) => {
        console.error("Failed to copy affiliate link:", err);
      });
  };
  

 
  return (
    <div>
      {seoData[0] && (
        <Helmet>
          <title>{seoData[0]?.profile_meta_title}</title>

          <meta
            name="description"
            content={seoData[0]?.profile_meta_description}
          />
          <meta
            name="keywords"
            content={JSON.parse(seoData[0]?.profile_meta_tags)}
          />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={seoData[0]?.profile_meta_title} />
          <meta
            property="og:description"
            content={seoData[0]?.profile_meta_description}
          />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.profile_meta_image}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={seoData[0]?.profile_meta_title} />
          <meta name="twitter:title" content={seoData[0]?.profile_meta_title} />
          <meta
            name="twitter:description"
            content={seoData[0]?.profile_meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.profile_meta_image}`}
          />
        </Helmet>
      )}
      <section class="tf-page-title ">
        <div class="tf-container">
          <div class="row">
            <div class="col-md-12">
              <ul class="breadcrumbs">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Profile</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="thumb-pagetitle w-100">
              <img
                className="w-100"
                style={{ objectFit: "fill" }}
                src={
                  coverImg ? `${global.imageURL}/nodeassets/${coverImg}` : img
                }
                alt="images"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="tf-dashboard tf-tab">
        <div className="tf-container">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={handleTabChange}
            className="dashboard-filter"
          >
            <div className="row ">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <div className="dashboard-user">
                  <div className="dashboard-infor">
                    <div className="avatar">
                      <img
                        src={
                          userImg
                            ? `${global.imageURL}/nodeassets/${userImg}`
                            : dummyImage
                        }
                        alt="images"
                        style={{ height: "120px" }}
                      />
                    </div>
                    <div className="name">{data[0]?.name}</div>
                    {/* <div className="pax">
                      <i className="fab fa-ethereum"></i>0x59485…82590
                    </div> */}
                    {/* <div className="description">
                      8,888 NFTs of beautiful, Asian women painstakingly-crafted
                      where even the most intricate
                    </div> */}
                    <ul className="social-item">
                      {fb && (
                        <li>
                          <a
                            href={`https://${fb}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      )}
                      {twitter && (
                        <li>
                          <a
                            href={`https://${twitter}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      )}
                      {discord && (
                        <li>
                          <a
                            href={`https://${discord}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-discord"></i>
                          </a>
                        </li>
                      )}
                      {linkedin && (
                        <li>
                          <a
                            href={`https://${linkedin}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      )}
                      {medium && (
                        <li>
                          <a
                            href={`https://${medium}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-medium"></i>
                          </a>
                        </li>
                      )}
                      {reddit && (
                        <li>
                          <a
                            href={`https://${reddit}`}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <i className="fab fa-reddit"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <TabList className="filter-menuu menu-tab">
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        My Projects
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M16.2586 4.8763L11.3086 2.01797C10.5003 1.5513 9.50026 1.5513 8.68359 2.01797L3.74193 4.8763C2.93359 5.34297 2.43359 6.20964 2.43359 7.1513V12.8513C2.43359 13.7846 2.93359 14.6513 3.74193 15.1263L8.69193 17.9846C9.50026 18.4513 10.5003 18.4513 11.3169 17.9846L16.2669 15.1263C17.0753 14.6596 17.5753 13.793 17.5753 12.8513V7.1513C17.5669 6.20964 17.0669 5.3513 16.2586 4.8763ZM10.0003 6.11797C11.0753 6.11797 11.9419 6.98464 11.9419 8.05964C11.9419 9.13464 11.0753 10.0013 10.0003 10.0013C8.92526 10.0013 8.05859 9.13464 8.05859 8.05964C8.05859 6.99297 8.92526 6.11797 10.0003 6.11797ZM12.2336 13.8846H7.76693C7.09193 13.8846 6.70026 13.1346 7.07526 12.5763C7.64193 11.7346 8.74193 11.168 10.0003 11.168C11.2586 11.168 12.3586 11.7346 12.9253 12.5763C13.3003 13.1263 12.9003 13.8846 12.2336 13.8846Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        Account Setting
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        Purchased Tickets
                      </a>
                    </Tab>
                    {/* <Tab>
                      <a>
                        {' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{' '}
                        Notifications
                      </a>
                    </Tab> */}
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        Private Sale Module
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        Notifications
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        Proposals
                      </a>
                    </Tab>
                    <Tab>
                      <a>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="svg-fill"
                            d="M17.3722 6.47085C17.7995 7.0155 17.3282 7.70964 16.6359 7.70964H2.66602C2.11373 7.70964 1.66602 7.26192 1.66602 6.70964V5.3513C1.66602 3.31797 3.31602 1.66797 5.34935 1.66797H7.28268C8.64102 1.66797 9.06602 2.10964 9.60768 2.83464L10.7744 4.38464C11.0327 4.7263 11.066 4.76797 11.5493 4.76797H13.8744C15.2932 4.76797 16.5581 5.43348 17.3722 6.47085Z"
                            fill="#3749E9"
                          />
                          <path
                            className="svg-fill"
                            d="M17.3194 8.95704C17.8704 8.95704 18.3175 9.40269 18.3194 9.95365L18.3327 13.8739C18.3327 16.3323 16.3327 18.3323 13.8743 18.3323H6.12435C3.66602 18.3323 1.66602 16.3323 1.66602 13.8739V9.95724C1.66602 9.40496 2.11372 8.95725 2.666 8.95724L17.3194 8.95704Z"
                            fill="#3749E9"
                          />
                        </svg>{" "}
                        Affiliates
                      </a>
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12 overflow-table">
                <div className="dashboard-content inventory content-tab">
                  <TabPanel>
                    <div className="inner-content inventory">
                      <h4 className="title-dashboard d-flex justify-content-between">
                        <div>My Projects</div>
                        <button
                          onClick={() =>
                            navigate(isAuthenticated ? "/create" : "/login")
                          }
                        >
                          Create Project
                        </button>
                      </h4>
                      <div className="table-ranking ">
                        {projects.length !== 0
                          ? projects?.map((t, i) => (
                              <div className="content-ranking">
                                <div className="col-rankingg">
                                  <div className="image">
                                    <img
                                      src={`${global.imageURL}/nodeassets/${t.project_iconImage}`}
                                      style={{ width: "100%", height: "60px" }}
                                      alt="ANTS"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-rankingg"
                                  onClick={() =>
                                    navigate(
                                      `/project-detail/${t.project_slug}`
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {t.project_name}
                                </div>
                                <div className="col-rankingg coin">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="22"
                                    viewBox="0 0 23 22"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.7619 0V8.13257L5.74624 10.8216L11.7619 8.13418V15.1949L4.89084 11.204L4.89062 11.2041L4.8907 11.2039L4.89062 11.2038L4.89077 11.2038L11.7619 0ZM11.7637 0L18.6357 11.2038L18.6359 11.2038L18.6359 11.2039L18.6359 11.2041L18.6357 11.204L11.7637 15.1949V8.13418L17.7802 10.8216L11.7637 8.13257V0ZM11.7626 16.4746V22.0005L4.88672 12.4844L11.7626 16.4746ZM11.7637 22.0005V16.4736L18.6359 12.4844L11.7637 22.0005Z"
                                      fill="#6B8CEF"
                                    />
                                  </svg>
                                  Ethereum
                                </div>
                                <div className="col-rankingg">0.45 Flow</div>
                                <div className="col-rankingg">4,915</div>
                                <div className="col-rankingg nofication">
                                  {" "}
                                  <i className="fas fa-circle"></i>{" "}
                                  {t.project_status}
                                </div>
                                <div className="col-rankingg dot relative">
                                  <div
                                    onClick={() => {
                                      setLinkClick((prev) => !prev);
                                      setProductId(t.project_id);
                                    }}
                                  >
                                    {linkClick &&
                                      t.project_id === productId && (
                                        <div className="updatePLinkAbsolute">
                                          <Link
                                            to={`/update-project/${t.project_id}`}
                                          >
                                            Edit
                                          </Link>
                                          <nav
                                            className="deletePLink"
                                            onClick={() =>
                                              handleDelete(t.project_id)
                                            }
                                          >
                                            Delete
                                          </nav>
                                        </div>
                                      )}
                                    <i className="fas fa-ellipsis-h"></i>
                                  </div>
                                </div>
                              </div>
                            ))
                          : "You don't have Projects created yet"}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <h4 className="title-dashboard">Your Profile</h4>
                      <AccountSetting data={data[0]} fetchData={fetchData} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content inventory">
                      <h4 className="title-dashboard d-flex justify-content-between">
                        <div>Purchased Tickets</div>
                        <button
                          onClick={() =>
                            navigate(isAuthenticated ? "/governance" : "/login")
                          }
                        >
                          Purchase a new ticekt
                        </button>
                      </h4>
                      <div className="table-ranking ">
                        {purchasedTickets.length !== 0
                          ? purchasedTickets?.map((t, i) => (
                              <div className="content-ranking">
                                <div
                                  className="col-rankingg"
                                  style={{ cursor: "pointer" }}
                                >
                                  {t.ticket_name}
                                </div>
                                <div className="col-rankingg">
                                  ANTS Tokens: {t.ticket_purchase_weightage}
                                </div>
                              </div>
                            ))
                          : "You don't have Tickets created yet"}
                      </div>
                    </div>
                  </TabPanel>
                  {/* <TabPanel>
                    <div className="inner-content profile">

                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <div className="title ">Notifications details</div>
                          <table className="rounded">
                            <thead>
                              <tr>
                                <th >Title</th>
                                <th >Description</th>
                                <th >
                                  User
                                </th>
                                
                              </tr>
                            </thead>
                            <tbody>
                            {filteredNotifications && filteredNotifications.length > 0 ? (
                              filteredNotifications.map((notifi, index) => (
                                  <tr key={index}>
                                    <td>{notifi.notification_title}</td>
                                    <td>{notifi.notification_description}</td>
                                    <td>{notifi.notification_user_name}</td>
                                  </tr>
                                ))
                              ) : (
                                <p className='noProposal'>Notification not availabel</p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </TabPanel> */}
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <div className="title">Private Sale Module</div>
                          <table
                            style={{
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              overflow: "hidden",
                            }}
                          >
                            <thead
                              style={{
                                backgroundColor: "#1F1F2C",
                                color: "#ffffff",
                              }}
                            >
                              <tr
                                style={{
                                  border: "0",
                                }}
                              >
                                <th className="text-white">Start Time</th>
                                <th className="text-white">End Time</th>
                                <th className="text-white">Token Price</th>
                                <th className="text-white">Total Token</th>
                                <th className="text-white">Status</th>
                                <th className="text-white">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formattedSaleData.map((data) => (
                                <tr
                                  key={data.id}
                                  style={{
                                    borderBottom: "1px solid #d1d5db",
                                    transition: "background-color 0.3s ease",
                                  }}
                                >
                                  <td>
                                    {data.private_sale_start_time_formatted}
                                  </td>
                                  <td>
                                    {data.private_sale_end_time_formatted}
                                  </td>
                                  <td>{data.private_sale_token_price}</td>
                                  <td>{data.private_total_token}</td>
                                  <td>{data.private_sale_status}</td>
                                  <td className="button">
                                    <button
                                        onClick={handleNavigateToPrivateSale}
                                      style={{
                                        paddingRight: "10px",
                                        paddingLeft: "10px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        borderRadius: "5px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      className="tf-button"
                                    >
                                      <span>More Details</span>
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-chevron-right"
                                        >
                                          <polyline points="9 18 15 12 9 6"></polyline>
                                        </svg>
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <div className="title">Notifications details</div>

                          {/* Conditional Rendering: Only show table if there are notifications */}
                          {filteredNotifications &&
                          filteredNotifications.length > 0 ? (
                            <table className="rounded">
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th>Description</th>
                                  <th>User</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredNotifications.map((notifi, index) => (
                                  <tr key={index}>
                                    <td>{notifi.notification_title}</td>
                                    <td>{notifi.notification_description}</td>
                                    <td>{notifi.notification_user_name}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            // Display message if no notifications available
                            <p className="noProposal text-center">
                              No notifications available
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <h4 className="title-dashboard d-flex justify-content-between">
                            <div className="title">Proposals</div>
                            <button
                              onClick={() =>
                                navigate(
                                  isAuthenticated ? "/createProposal" : "/login"
                                )
                              }
                            >
                              Create Proposal
                            </button>
                          </h4>
                          <table className="rounded">
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Start (Date and Time)</th>
                                <th>End (Date and Time)</th>
                                <th>View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {proposalData  && proposalData .length > 0 ? (
                                proposalData .map((proposal, index) => (
                                  <tr key={index}>
                                    <td>{proposal.proposal_title}</td>
                                    <td>{proposal.proposal_description}</td>
                                    <td>{proposal.proposal_start}</td>
                                    <td>{proposal.proposal_end}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <button
                                        style={{
                                          width: "85px",
                                          height: "50px",
                                          textAlign: "center",
                                          padding: 0,
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                        }}
                                        onClick={() =>
                                          navigate("/proposal-detail", { state: proposal })
                                        }
                                      >
                                        View
                                      </button>
                                      {proposal.proposal_user_id == userUid && (
                                        <button
                                          onClick={() =>
                                            navigate("/createProposal", {
                                              state: { dataObj: proposal },
                                            })
                                          }
                                          style={{
                                            width: "85px",
                                            height: "50px",
                                            textAlign: "center",
                                            padding: 0,
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <p className="noProposal">
                                  No proposals available.
                                </p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="inner-content profile">
                      <div className="form-edit-profile">
                        <div className="user-profile">
                          <h4 className="title-dashboard d-flex justify-content-between">
                          <div className="title">Affiliated User</div>
                           <button onClick={handleShareLink}>
                              Share link
                            </button>
                            </h4>
                          {affiliatedUsers &&
                          affiliatedUsers.length > 0 ? (
                            <table className="rounded">
                              <thead>
                                <tr>
                                  <th>User</th>
                                </tr>
                              </thead>
                              <tbody>
                                {affiliatedUsers.map((notifi, index) => (
                                  <tr key={index}>
                                    <td>{notifi.wallet_address}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p className="noProposal text-center">
                              No affiliates available
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
