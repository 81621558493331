import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Navigation, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';

Category.propTypes = {
  data: PropTypes.array,
};

function Category(props) {
  const navigate = useNavigate();
  const { data } = props;
  const [filteredData, setfilteredData] = useState([]);

  useEffect(() => {
    setfilteredData(data);
  }, [data]);

  const handleFilter = (e) => {
    const name = e.toLowerCase();
    if (name !== 'all') {
      const filteredItem = data.filter(
        (t) => t.project_type.toLowerCase() == name
      );
      setfilteredData(filteredItem);
    } else {
      setfilteredData(data);
    }
  };

  const img1 =
    'https://binaseareact.vercel.app/static/media/product-category-5.1c65e990e476ee79f883.jpg';
  const img2 =
    'https://binaseareact.vercel.app/static/media/product-category-1.d5b8b774d37a5eba57e3.jpg';
  const img3 =
    'https://binaseareact.vercel.app/static/media/product-category-2.641b81be619cd538a455.jpg';
  const img4 =
    'https://binaseareact.vercel.app/static/media/product-category-3.00f8ba2018faf1478044.jpg';
  const img5 =
    'https://binaseareact.vercel.app/static/media/product-category-5.1c65e990e476ee79f883.jpg';
  return (
    <section className="tf-section tf-category">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-heading mb40 wow fadeInUp">
              <h4 className="heading">All Categories</h4>
            </div>
          </div>
          <div className="col-md-12">
            <Tabs>
              <div className="d-flex justify-content-between mb-wr">
                <TabList>
                  <Tab onClick={() => handleFilter('All')}>ALL</Tab>
                  <Tab onClick={() => handleFilter('NGO')}>NGO</Tab>
                  <Tab onClick={() => handleFilter('Profit-Driven')}>
                    Profitable
                  </Tab>
                </TabList>
              </div>
            </Tabs>
          </div>
          <div className="col-md-12 wow fadeInUp">
            <Swiper
              modules={[Navigation, Scrollbar, A11y, Autoplay]}
              spaceBetween={20}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                500: {
                  slidesPerView: 2,
                },
                767: {
                  slidesPerView: 3,
                },
                991: {
                  slidesPerView: 4,
                },
              }}
              className="product-category"
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
            >
              {filteredData?.map((idx, i) => (
                <SwiperSlide key={idx.cate_id} >
                  <div className="tf-product-category" onClick={(e) => {
                    navigate(`/projects?project_type=${idx.project_type}&project_category=${idx.cate_id}`)
                  }}>
                    <img
                      src={`${global.imageURL}/nodeassets/${idx.cate_img}`}
                      alt="ANTS"
                    />
                    <div className="category">
                      <Link>{idx.cate_name}</Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category;
