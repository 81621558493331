import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../css/Wallet.css'
import icon1 from '../assets/images/svg/icon-wallet-1.svg'
import icon2 from '../assets/images/svg/icon-wallet-2.svg'
import icon3 from '../assets/images/svg/icon-wallet-3.svg'
import icon4 from '../assets/images/svg/icon-wallet-4.svg'
import icon5 from '../assets/images/svg/icon-wallet-5.svg'
import icon6 from '../assets/images/svg/icon-wallet-6.svg'
import icon7 from '../assets/images/svg/icon-wallet-7.svg'
import icon8 from '../assets/images/svg/icon-wallet-8.svg'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
Wallet.propTypes = {

};

function Wallet(props) {
    const [dataWallet] = useState([
        {
            id: 1,
            img: icon1,
            cate: '',
            title: 'Meta Mask',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
        {
            id: 2,
            img: icon2,
            cate: 'none',
            title: 'Bitski',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
        {
            id: 3,
            img: icon3,
            cate: '',
            title: 'Wallet Connect',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
        {
            id: 4,
            img: icon4,
            cate: 'none',
            title: 'Coin Base',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
        {
            id: 5,
            img: icon5,
            cate: '',
            title: 'Authereum',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
        {
            id: 6,
            img: icon6,
            cate: '',
            title: 'Kaikas',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
        {
            id: 7,
            img: icon7,
            cate: 'none',
            title: 'Torus',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
        {
            id: 8,
            img: icon8,
            cate: '',
            title: 'Fortmatic',
            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. '
        },
    ])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])

    useEffect(() => {
        axios
            .get(`${global.backendUrl}/subscriptiontitle`)
            .then((res) => {
                setdata(res?.data);
            })
            .catch((err) => console.log(err));

        axios
            .get(`${global.backendUrl}/createplans`)
            .then((res) => {
                setdata2(res?.data);
            })
            .catch((err) => console.log(err));
    }, [])
    return (
        <div>

            <section className="tf-page-title">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><Link to="#">Pages</Link></li>
                                <li>Subscription Plans</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-connect-wallet">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="tf-heading style-5">
                                <p className='text-center' style={{ textTransform: 'uppercase', fontWeight: 'bold', color: "#3649E9" }}>{data[0]?.subscription_tag}</p>
                                <h4 className="heading">{data[0]?.subscription_title}</h4>
                                {/* <p className="sub-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit. </p> */}
                            </div>
                        </div>
                        {
                            data2?.map((idx, i) => (
                                <div key={idx.id} className="col-lg-4 col-md-6">
                                    <div className={`tf-wallet ${idx.plan_membership == 'PREMIUM' && 'newShadow'}`}>
                                        {/* <div className="icon">
                                            <img src={idx.img} alt="ANTS" />
                                            <span className={`label ${idx.cate}`}>BETA</span>
                                        </div> */}
                                        <h3 className="title" style={{ }}>{idx.plan_membership}</h3>
                                        <p style={{ fontSize: "16px",marginBottom:"15px" }}>{idx.plan_name}</p>
                                        <div className="d-flex align-items-center">
                                            <h2 className="m-0" style={{ fontSize:"50px",color:"#fff" }}>
                                                ${idx.plan_amount}
                                            </h2>
                                            <sub className="fs-5">/month</sub>
                                        </div>
                                        <p className="content" dangerouslySetInnerHTML={{ __html: idx.plan_description }} />
                                        <div className="d-flex align-items-center gap-2 justify-content-center text-center mb-3">
                                            <p>
                                                Allowed Vote: <span>{idx.plan_totalvotes}</span>
                                            </p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center border-top">
                                            <button
                                                type="button"
                                                className={`default-btn default-btn--small mt-3 btn w-100  ${idx.plan_membership == 'PREMIUM' ? 'sub-activebtn' : 'sub-btn'}`}
                                                // onClick={() => handlePricings(p.plans_id, p)}
                                                onClick={handleShow}
                                                data-bs-toggle="modal"
                                                data-bs-target={`#wallet-option-${i}`}
                                            >
                                                {idx?.project_type === "NGO" ?"Donate": "Invest"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <div class="modal-header position-absolute w-100" style={{ zIndex: 1, borderBottom: 0 }}>
                        <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>

                    <div className='p-4' style={{ borderBottom: "1px solid white" }}>
                        <h5 className='text-center'>CONNECT PAYMENT METHOD</h5>
                    </div>

                    <div className='p-5'>
                        <div className='d-flex justify-content-center align-items-center mb-5'>
                            <div className='p-3' style={{border:'1px solid lightgray',borderRadius:"6px"}}>
                                <img src='https://antsprotocol.unialsolutions.com/static/media/MetaMask.07397b298d60adbff5602fc0d930ce9d.svg' style={{ height: "80px", width: "80px" }} />
                            </div>
                            <div className='p-3' style={{border:'1px solid lightgray',borderRadius:"6px",marginLeft:"50px"}}>
                                <img src='https://antsprotocol.unialsolutions.com/static/media/stripe.7d49df9572e99242f8fcce3ef9a19f5b.svg' style={{ height: "80px", width: "80px" }} />
                            </div>
                        </div>
                        <p className='text-center'>
                            By choosing payment method, you agree to our Terms of Service
                            and our Privacy Policy.
                        </p>
                    </div>
                </Modal>
            </section>

        </div>
    );
}

export default Wallet;