import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import './App.scss'
import routes from './pages';
import Page404 from './pages/404';
import '../src/assets/binasea.css';
import '../src/assets/font-awesome.css';
// import '../src/assets/style.css'
global.imageURL = 'https://antsprotocoladminapi.unialsolutions.com';


export const WebsiteUrl = 'https://antsv3.vercel.app';
// global.backendUrl = 'https://antsv3api.vercel.app';
global.backendUrl = 'https://antsprotocoladminapi.unialsolutions.com';
// global.backendUrl = 'http://localhost:5000';
// deployment
function App() {
  const location = useLocation();
  const prevLocation = useRef(location.pathname);
  const [scroll, setScroll] = useState(false);
  // useEffect(() => {
  //   AOS.init({
  //     duration: 2000,
  //   });

  // }, []);

  // useEffect(() => {
  //   if (prevLocation.current !== location.pathname) {
  //     // Update previous location and reload page
  //     prevLocation.current = location.pathname;
  //     // window.location.reload();
  //   }
  // }, [location]);



  return (
    <div >
      <Header />
      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
