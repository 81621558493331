import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import avt1 from '../assets/images/author/author-login-1.png';
import avt2 from '../assets/images/author/author-login-2.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useStateContext } from '../components/context/AppContext';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import Web3 from 'web3';

Login.propTypes = {};

function Login(props) {
  const { setisAuthenticated } = useStateContext();
  const navigate = useNavigate();

  const [data, setdata] = useState([]);

  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    if (!email) {
      return showError('Enter the Email');
    }
    if (!password) {
      return showError('Enter the Password');
    }

    function showError(fieldName) {
      return toast.error(`Please ${fieldName}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    console.log("data", data)
    const emailChecker = data.filter((t) => t.email === email);
    if (emailChecker.length == 0) {
      return toast.error(`Incorrect Email`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (emailChecker[0].password !== password) {
      return toast.error(`Incorrect Password`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setisLoading(true);
    toast.success(`Loged In Successfully`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    var user = {
      email: emailChecker[0].email,
      uid: emailChecker[0].uid,
      role: emailChecker[0].role,
      name: emailChecker[0].name,
    };
    localStorage.setItem('antsInformation', JSON.stringify(user));
    setisAuthenticated(true);
    setisLoading(false);
    navigate('/dashboard');
  };
  
  const loadWeb3 = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        console.log("accounts", accounts)
        let wallet_address = accounts[0]
        const urlParams = new URLSearchParams(window.location.search);
        const affiliate_id = urlParams.get('affiliate_id');  
        axios.get(`${global.backendUrl}/user/wellet/${wallet_address}`)
          .then((res) => {
            console.log("res", res)
            if (res.data.length > 0) {
              var user = {
                email: res.data[0].email,
                uid: res.data[0].uid,
                wallet_address: res.data[0].wallet_address,
                role: 'user',
                name: "",
                affiliate_id:res.data[0].affiliate_id,
              }
              console.log("usersssssssssss",user)
              localStorage.setItem("antsInformation", JSON.stringify(user))
              setisAuthenticated(true)
              navigate('/dashboard')
            } else {
              let formdata = {
                wallet_address: wallet_address,
                role: "user",
                affiliate_id: affiliate_id || null,
              }
              axios.post(`${global.backendUrl}/users/insert/wallet`, formdata)
                .then((res) => {
                  toast.success(`Signed Up Successfully`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                  setisLoading(false)
                  var user = {
                    email: "",
                    uid: res.data.insertId,
                    wallet_address: wallet_address,
                    role: 'user',
                    name: ""
                  }
                  localStorage.setItem("antsInformation", JSON.stringify(user))
                  setisAuthenticated(true)
                  navigate('/dashboard')
                }).catch((err) => {
                  console.log(err)
                  setisLoading(false)
                })
            }

          }).catch((err) => {
            console.log(err)
            setisLoading(false)
          })
        // setAccounts(accounts);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('Metamask not detected');
    }
  };
  const handleGooleLogin = (e) => {
    const googleEmail = e.email;
    const emailChecker = data.filter((t) => t.email === googleEmail);
    if (emailChecker.length == 0) {
      let formData = {
        email: googleEmail,
        password: '',
        role: 'user',
        name: e.name,
      };
      axios
        .post(`${global.backendUrl}/users/insert`, formData)
        .then((response) => {
          var user = {
            email: googleEmail,
            uid: response.data.insertId,
            role: 'user',
          };
          toast.success(`Google SignUp Successfully`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.setItem('antsInformation', JSON.stringify(user));
          setisAuthenticated(true);
          navigate('/dashboard');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      var user = {
        email: emailChecker[0].email,
        uid: emailChecker[0].uid,
        role: emailChecker[0].role,
      };
      localStorage.setItem('antsInformation', JSON.stringify(user));
      setisAuthenticated(true);

      toast.success(`Google Signin Successfully`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        setdata(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      {/* <section className="tf-page-title style-2">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/blog-v2">Home</Link>
                </li>
                <li>Login</li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      <section className="tf-login">
        <div className="tf-container">
          <div className="row justify-content-center">
            {/* <div className="col-md-12">
              <div className="tf-heading style-5">
                <h4 className="heading">Login Here</h4>
              </div>
            </div> */}
            <div className="col-xl-6 col-lg-8 col-md-12">
              
              {/* <fieldset>
                <input
                  id="email"
                  name="email"
                  tabindex="1"
                  aria-required="true"
                  required=""
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </fieldset>
              <fieldset className="mb24">
                {' '}
                <input
                  id="showpassword"
                  name="password"
                  tabindex="2"
                  aria-required="true"
                  type={`${showPassword ? 'text' : 'password'}`}
                  placeholder="Password"
                  required=""
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
                <span className="btn-show-pass">
                  {showPassword ? (
                    <i
                      onClick={() => setShowPassword((prev) => !prev)}
                      class="fa-regular fa-eye"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setShowPassword((prev) => !prev)}
                      className="far fa-eye-slash"
                    ></i>
                  )}
                </span>
              </fieldset>
              <div className="forgot-pass-wrap">
              </div>
              <button
                className="submit"
                onClick={handleLogin}
                disabled={isLoading}
              >
                Login
              </button> */}
              <button
                className="submit orange_bg mt-4"
                onClick={loadWeb3}
                disabled={isLoading}
              >
                Login with Metamask
              </button>

              {/* <div className="title-login mt-5">Or login with social</div>
                            <div className="button-gg mb33 d-flex justify-content-center">
                                <GoogleLogin
                                    // width={'500px'}
                                    type='icon'
                                    // text='signup_with'
                                    // logo_alignment='center'
                                    // theme='filled_black'
                                    onSuccess={credentialResponse => {
                                        var decoded = jwt_decode(credentialResponse.credential)
                                        handleGooleLogin(decoded)
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </div> */}

              {/* <div className="mt-5 text-center">
                Don't Have an Account?{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => navigate('/signup')}
                >
                  Signup Here
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
