import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './App.scss'

import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import AppContext, { ThemeContextProvider } from "./components/context/AppContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <GoogleOAuthProvider clientId="1038170166962-u8d8fi64utdsg78o6dc97sk4530dfni6.apps.googleusercontent.com">
            <ThemeContextProvider>
                <BrowserRouter>
                    <ScrollToTop />
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                    <ToastContainer />
                </BrowserRouter>
            </ThemeContextProvider>
        </GoogleOAuthProvider>
    </React.Fragment>
);

