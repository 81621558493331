import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';

import { Helmet } from 'react-helmet';

import './styles.scss';
import CountingLaunchPage from './CountingLaunchPage';
import WhyLaunchWithUs from './WhyLaunchWithUs';
import ProcessSteps from './ProcessSteps';
import MarketingSupport from './MarketingSupport';
import CaseStudies from './CaseStudies';
import NotReady from './NotReady';
import MajorExchanges from './MajorExchanges';
import Support from './Support';
import { Link } from 'react-router-dom';
import axios from 'axios'
import newssss from '../../assets/vedio/antsprotocolorg.mp4'

function LaunchPage() {
  const [uid, setuid] = useState('');
  const [seoData, setSeoData] = useState([]);

  useEffect(() => {
    fetchUsers()
    axios
      .get(`${global.backendUrl}/launchSeo`)
      .then((res) => {
        setSeoData(res?.data);
      })
      .catch((err) => console.log(err));
  }, [])
  const fetchUsers = () => {
    const AccountInfo = localStorage.getItem('antsInformation');
    const parsedInfo = JSON.parse(AccountInfo);
    setuid(parsedInfo?.uid);
  };
  return (
    <>
      {seoData[0] && (
        <Helmet>
          <title>{seoData[0]?.launch_meta_title}</title>

          <meta name="description" content={seoData[0]?.launch_meta_description} />
          <meta name="keywords" content={JSON.parse(seoData[0]?.launch_meta_tags)} />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={seoData[0]?.launch_meta_title} />
          <meta property="og:description" content={seoData[0]?.launch_meta_description} />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.launch_meta_image}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={seoData[0]?.launch_meta_title} />
          <meta name="twitter:title" content={seoData[0]?.launch_meta_title} />
          <meta
            name="twitter:description"
            content={seoData[0]?.launch_meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.launch_meta_image}`}
          />
        </Helmet>
      )}

      <section className="tf-blog-detail launch-section">
        <div className="tf-container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="detail-inner">
                <div className="content-top">
                  <h4 className="title">Launch your project with ANTS Protocol</h4>
                  <div className="author-infor">
                  </div>
                  <Link to={`${uid ? "/create" : "/login"}`} >
                    <div className="launch-button mt-4">
                      Apply to Raise
                    </div>
                  </Link>

                </div>
                <div className="image">
                  <div className="video">
                    {/* <iframe
                      width="100%"
                      height="515"
                      src="https://www.youtube.com/embed/bajBCZ5gy00"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen>
                    </iframe> */}
                  <video
  style={{ borderRadius: '20px', width: '100%' }}
  className="w-100 w-sm-50 w-md-75 w-lg-100"
  controls
>
  <source src={newssss} type="video/mp4" />
</video>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CountingLaunchPage />
        <WhyLaunchWithUs />
        <Support />
        <ProcessSteps />
        <MajorExchanges />
        <MarketingSupport />
        <CaseStudies />
        {/* <NotReady /> */}
      </section>

    </>
  );
}

export default LaunchPage;
