import React from 'react';

function NotReady() {
  return (
    <section className="tf-blog-detail">
      <div className="tf-container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="detail-inner">
              <div className="content-top">
                <h4 className="title">Not 100% ready today?</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="card-not-ready">
              <h5 className="card-title-not-ready">Raise funds publicly</h5>
              <p >Access a global reach of dedicated backers in a transparent and decentralized setting.</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card-not-ready">
              <h5 className="card-title-not-ready">Build a community</h5>
              <p >Grow and nurture your most loyal supporters from the very beginning. They’ve got your back!</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card-not-ready">
              <h5 className="card-title-not-ready">Decrease risk</h5>
              <p >Take full control of your fundraising and leverage ANTS’s experience and community.</p>
            </div>
          </div>
        </div>
        <div className='button-section'>
          <div className="launch-button mt-4">
            Learn More
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotReady;

