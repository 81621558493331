import { createContext, useContext, useState, useEffect } from "react";
import { getPrivateSale, getproposal } from "../../api/Api";

const ThemeContext = createContext();

export function ThemeContextProvider({ children }) {
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [saleData, setSaleData] = useState(null);
  const [proposalData, setProposalData] = useState(null);

  useEffect(() => {
    let uinfo = JSON.parse(localStorage.getItem("antsInformation")) || [];
    setisAuthenticated(uinfo.length !== 0);

    // Fetch and store sale data
    const fetchSaleData = async () => {
      try {
        const res = await getPrivateSale();
        const activeSales = res.data.filter(
          (sale) => sale.private_sale_status === "Active"
        );

        if (activeSales.length > 0) {
          localStorage.setItem(
            "privateSaleData",
            JSON.stringify(activeSales[0])
          );
          setSaleData(activeSales[0]);
        }
      } catch (error) {
        console.error("Error fetching sale data:", error);
      }
    };

    // Fetch and store proposal data
    const fetchProposalData = async () => {
      try {
        const res = await getproposal();
        if (res.data && res.data.length > 0) {
          localStorage.setItem("proposalData", JSON.stringify(res.data));
          setProposalData(res.data);
        }
      } catch (error) {
        console.error("Error fetching proposal data:", error);
      }
    };

    // Check and load stored data
    const storedSaleData = localStorage.getItem("privateSaleData");
    const storedProposalData = localStorage.getItem("proposalData");

    if (storedSaleData) {
      setSaleData(JSON.parse(storedSaleData));
    } else {
      fetchSaleData();
    }

    if (storedProposalData) {
      setProposalData(JSON.parse(storedProposalData));
    } else {
      fetchProposalData();
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        isAuthenticated,
        setisAuthenticated,
        saleData,
        setSaleData,
        proposalData,
        setProposalData
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export const useStateContext = () => useContext(ThemeContext);