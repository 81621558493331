import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import img1 from '../images/svg/icon-create-1.svg'
// import img2 from '../images/svg/icon-create-2.svg'
// import img3 from '../images/svg/icon-create-3.svg'
// import img4 from '../images/svg/icon-create-4.svg'
import img1 from '../../assets/images/svg/icon-create-1.svg';
import img2 from '../../assets/images/svg/icon-create-2.svg';
import img3 from '../../assets/images/svg/icon-create-3.svg';
import img4 from '../../assets/images/svg/icon-create-4.svg';


function CountingLaunchPage() {
  return (
    <section className="tf-section tf-create-and-sell">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={img1} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title pt-4">
                  <Link to="#">Total Raised</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                $9M
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={img2} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title pt-4">
                  <Link to="#">Funded Projects</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                137
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={img3} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title pt-4">
                  <Link to="#">Participants</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                90
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="tf-create wow fadeInUp border-gray" data-wow-delay="0.2s">
              <div
                style={{
                  display: 'flex',
                  gap: '5px',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <div className="icon">
                    <img src={img4} alt="ANTS" />
                  </div>
                </div>
                <h6 className="title pt-4">
                  <Link to="#">ANTS Supply</Link>
                </h6>
              </div>
              <p
                style={{ fontSize: '30px', marginTop: '10px' }}
              >
                100,000
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CountingLaunchPage;

