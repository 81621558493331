import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';

import { Helmet } from 'react-helmet';

import './styles.scss';
import Tickets from './Tickets';
import axios from 'axios';

function GovernancePage() {
  const [seoData, setSeoData] = useState([]);
  useEffect(() => {
    axios
      .get(`${global.backendUrl}/governanceSeo`)
      .then((res) => {
        setSeoData(res?.data);
      })
      .catch((err) => console.log(err));
  }, [])
  return (
    <>
      {seoData[0] && (
        <Helmet>
          <title>{seoData[0]?.governance_meta_title}</title>

          <meta name="description" content={seoData[0]?.governance_meta_description} />
          <meta name="keywords" content={JSON.parse(seoData[0]?.governance_meta_tags)} />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={seoData[0]?.governance_meta_title} />
          <meta property="og:description" content={seoData[0]?.governance_meta_description} />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.governance_meta_image}`}
          />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={seoData[0]?.governance_meta_title} />
          <meta name="twitter:title" content={seoData[0]?.governance_meta_title} />
          <meta
            name="twitter:description"
            content={seoData[0]?.governance_meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${seoData[0]?.governance_meta_image}`}
          />
        </Helmet>
      )}

      <section className="tf-blog-detail launch-section">
        <Tickets />
      </section>

    </>
  );
}

export default GovernancePage;
