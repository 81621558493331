import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../components/context/AppContext';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
SignUp.propTypes = {};

function SignUp(props) {
  const { setisAuthenticated } = useStateContext();
  const navigate = useNavigate();

  const [data, setdata] = useState([]);

  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  console.log('showPassword', showPassword);
  const handleSignup = () => {
    if (!name) {
      return showError('Please Enter Your Name');
    }
    if (!email) {
      return showError('Please Enter the Email');
    }
    if (!password) {
      return showError('Please Enter the Password');
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return showError('Please enter a valid email address.');
    }
    if (password.length < 8) {
      return showError('Password length should be 8');
    }

    function showError(fieldName) {
      return toast.error(`${fieldName}`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const userNameChecker = data.filter(
      (t) => t.name.toLowerCase() === name.toLowerCase()
    );
    if (userNameChecker.length !== 0) {
      return toast.error(`This User Name is already in use, try unique Name`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const emailChecker = data.filter((t) => t.email === email);
    if (emailChecker.length == 0) {
      setisLoading(true);
      const otp = Math.floor(1000 + Math.random() * 9000);
      const formData = {
        user_email: email,
        subject: 'Authentication',
        message: `Your OTP for sign up ${otp}`,
      };

      axios
        .post(`${global.backendUrl}/authentication`, formData)
        .then((response) => {
          setisLoading(false);
          toast.success(`OTP Sent Successfully`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/otp', {
            state: {
              dataObj: {
                name: name,
                email: email,
                password: password,
                otp: otp,
              },
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setisLoading(false);
        });
    } else {
      toast.error(`This Email is already in use, try another email`, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleGooleLogin = (e) => {
    const googleEmail = e.email;
    const emailChecker = data.filter((t) => t.email === googleEmail);
    if (emailChecker.length == 0) {
      let formData = {
        email: googleEmail,
        password: '',
        role: 'user',
        name: e.name,
      };
      axios
        .post(`${global.backendUrl}/users/insert`, formData)
        .then((response) => {
          var user = {
            email: googleEmail,
            uid: response.data.insertId,
            role: 'user',
          };
          toast.success(`Google SignUp Successfully`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          localStorage.setItem('antsInformation', JSON.stringify(user));
          setisAuthenticated(true);
          navigate('/dashboard');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.success(`Google Signin Successfully`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      var user = {
        email: emailChecker[0].email,
        uid: emailChecker[0].uid,
        role: emailChecker[0].role,
      };
      localStorage.setItem('antsInformation', JSON.stringify(user));
      setisAuthenticated(true);
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((response) => {
        setdata(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section className="tf-page-title style-2">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumbs">
                <li>
                  <Link to="/blog-v2">Home</Link>
                </li>
                <li>Sign Up</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-login">
        <div className="tf-container">
          <div className="row justify-content-center">
            {/* <div className="col-md-12">
              <div className="tf-heading style-2">
                <h4 className="heading">Sign Up Here</h4>
              </div>
            </div> */}
            <div className="col-xl-6 col-lg-9 col-md-12">
              <fieldset>
                <input
                  id="name"
                  name="name"
                  tabindex="1"
                  aria-required="true"
                  required=""
                  type="text"
                  placeholder="User Name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </fieldset>
              <fieldset>
                <input
                  id="email"
                  name="email"
                  tabindex="1"
                  aria-required="true"
                  required=""
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </fieldset>
              <fieldset>
                {' '}
                <input
                  id="showpassword"
                  name="password"
                  tabindex="2"
                  aria-required="true"
                  type={`${showPassword ? 'text' : 'password'}`}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  required=""
                />
                <span className="btn-show-pass">
                  {showPassword ? (
                    <i
                      onClick={() => setShowPassword((prev) => !prev)}
                      class="fa-regular fa-eye"
                    ></i>
                  ) : (
                    <i
                      onClick={() => setShowPassword((prev) => !prev)}
                      className="far fa-eye-slash"
                    ></i>
                  )}
                </span>
              </fieldset>
              <div className="forgot-pass-wrap">
                <label>
                  I agree to the terms and services
                  <input type="checkbox" />
                  <span className="btn-checkbox"></span>
                </label>
              </div>
              <button
                className="submit"
                disabled={isLoading}
                onClick={handleSignup}
              >
                {isLoading ? 'Loading...' : 'Signup'}
              </button>

              {/* <div className="title-login mt-5">Or login with social</div>
                            <div className="button-gg mb33 d-flex justify-content-center">
                                <GoogleLogin
                                    // width={'500px'}
                                    type='icon'
                                    // text='signup_with'
                                    // logo_alignment='center'
                                    // theme='filled_black'
                                    onSuccess={credentialResponse => {
                                        var decoded = jwt_decode(credentialResponse.credential)
                                        handleGooleLogin(decoded)
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </div> */}

              <div className="mt-5 text-center">
                Already Have an Account?{' '}
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => navigate('/login')}
                >
                  Signin Here
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUp;
