import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const CardModal = (props) => {
    const [amount, setamount] = useState()
    const [isLoading, setisLoading] = useState(false)

    const handleContinue = () => {
        // if (!props.userId) { return showError("login again! There is an error") };
        // if (!amount) { return showError("enter the amount") };



        // function showError(fieldName) {
        //     toast.error(`Please ${fieldName}`, {
        //         position: "top-right",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }

        // setisLoading(true)

        // const formData = { project_id: props.projectId, uid: props.userId, donation_amount: amount }
        // axios.post(`${global.backendUrl}/donation/insert`, formData)
        //     .then((response) => {
        //         toast.success(`Donation Sent Successfully`, {
        //             position: "top-right",
        //             autoClose: 3000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        //         setamount("")
        //         setisLoading(false)
        //         props.onHide()
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //         setisLoading(false)
        //     })
    }

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body space-y-20 pd-40">
                <h3>Donate Here</h3>
                <p className="text-center sub-heading">You must bid a least <span className="price color-popup">4.89 ETH</span></p>
                <input type="number" value={amount} onChange={e => setamount(e.target.value)} className="form-control mb-5" placeholder="Enter amount here in dollar" />
                <p className="label-1">Enter quantity. <span className="color-popup">5 available</span>
                </p>
                <input type="text" className="form-control quantity form-bottom" />
                <div className="d-flex justify-content-between detail-1">
                    <p> You must bid at least:</p>
                    <p className="text-right price color-popup"> 4.89 ETH </p>
                </div>
                <div className="d-flex justify-content-between detail-2">
                    <p> Service free:</p>
                    <p className="text-right price color-popup"> 0,89 ETH </p>
                </div>
                <div className="d-flex justify-content-between detail-3">
                    <p> Total bid amount:</p>
                    <p className="text-right price color-popup"> 4 ETH </p>
                </div>
                <Link to="/wallet-connect" className="button-popup" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close">Continue</Link>
                {/* <button className="button-popup w-100" disabled={isLoading} onClick={handleContinue}>Continue</button> */}
            </div>
        </Modal>

    );
};

export default CardModal;
