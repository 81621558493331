import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Accordion } from 'react-bootstrap-accordion';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import { truncate } from 'lodash';

Faqs.propTypes = {

};

const metadata = {
    title: "Expert Software Development Company in AI, Blockchain, Mobile & Web",
    description: "UNIAL is a premier software development company specializing in AI, Blockchain, Mobile App, and Web Development. We provide secure, innovative solutions designed to drive business growth and technological advancement",
    url: "https://www.unialsolutions.com",
    siteName: "https://www.unialsolutions.com",
    logo: "/images/favicons.png",
    twitter: "unialsolutions",
    linkedin: "unialsolutions",
    instagram: "unialsolutions",
    pinterest: "unialsolutions",
    keywords: "UNIAL, software development, artificial intelligence, AI, blockchain technology, mobile app development, web development, digital solutions, tech innovation, business growth, secure digital services",
};


function Faqs(props) {

    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])

    useEffect(() => {
        axios
            .get(`${global.backendUrl}/benefitstitle`)
            .then((res) => {
                console.log("res", res)
                setdata(res?.data);
            })
            .catch((err) => console.log(err));

        axios
            .get(`${global.backendUrl}/signupbenefits`)
            .then((res) => {
                const updatedJsonArray = res?.data.map(item => ({
                    ...item,
                    isView: false
                }));
                setdata2(updatedJsonArray);
            })
            .catch((err) => console.log(err));

    }, [])
    const updateIsView = (index, isView) => {
        const tempArray = [...data2]
        tempArray[index].isView = isView;
        setdata2(tempArray)
    }
    return (
        <div>
            {data.length > 0 &&
                <Helmet>
                    <title>{data[0].benefits_meta_title || metadata.title}</title>
                    <meta name="description" content={data[0].benefits_meta_description || metadata.description} />
                    <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                    <meta charSet="UTF-8" />
                    <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                    <meta property="og:title" content={data[0].benefits_meta_title || metadata.title} />
                    <meta property="og:url" content={`${global.imageURL}/benefits`} />
                    <meta name="keywords" content={JSON.parse(data[0]?.benefits_meta_tags) || metadata.keywords} />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta property="og:image" content={`${global.imageURL}/nodeassets/${data[0]?.benefits_meta_image}` || metadata.logo} />
                    <link rel="icon" href={metadata.logo} />
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                name: data[0].benefits_meta_title || metadata.title,
                                url: metadata.url,
                                logo: data[0].benefits_meta_image || metadata.logo,
                                description: data[0].benefits_meta_description || metadata.description,
                                contactPoint: {
                                    "@type": "ContactPoint",
                                    telephone: "+92-301-3949480",
                                    contactType: "customer service",
                                },
                                sameAs: [
                                    `https://www.linkedin.com/company/${metadata.linkedin}`,
                                    `https://twitter.com/${metadata.twitter}`,
                                    `https://instagram.com/${metadata.instagram}`,
                                    `https://pinterest.com/${metadata.instagram}`,
                                ],
                            }),
                        }}
                    />
                </Helmet>
            }
            {/* <section className="tf-page-title style-2">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Profile</Link></li>
                                <li>Benefits</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section> */}

            <section className="tf-faq">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 px-5 ">
                            <div className="tf-heading style-5">
                                <p className='text-center' style={{ textTransform: 'uppercase', fontWeight: 'bold', color: "#3649E9" }}>{data[0]?.benefits_tag}</p>
                                <h4 className="heading">{data[0]?.benefits_title}</h4>
                            </div>
                            <div className='row mx-5'>
                                {data2.map((item, index) => {
                                    return (
                                        <div className="col-md-3">
                                            <div key={item.id} className="sc-product style2">
                                                <div className="features">
                                                    <div className="product-media">
                                                        <img
                                                            className='my-5'
                                                            src={`${global.imageURL}/nodeassets/${item.benefits_image}`}
                                                            style={{ height: '250px' }}
                                                            alt="images"
                                                        />
                                                        <div className='w-100 text-center'>
                                                            <h6 className="tag my-2">
                                                                {item.benefits_title}
                                                            </h6>
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.isView ? item.benefits_text : truncate(item.benefits_text, { length: 80 }),
                                                                }}
                                                            />
                                                            {/* <p >
                                                                {item.isView ? item.benefits_text : truncate(item.benefits_text, { length: 80 })}
                                                            </p> */}
                                                            <div className="button my-1">
                                                                <div
                                                                    className="tf-button p-3"
                                                                    onClick={() => updateIsView(index, !(item.isView))}
                                                                >
                                                                    {item.isView
                                                                        ? 'View less'
                                                                        : 'View more'}
                                                                    <i className="fal fa-long-arrow-right ml-1"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {/* 
                            <div className="tf-accordion">
                                {
                                    data2.map(idx => (
                                        <Accordion key={idx.id} title={idx.benefits_title} className='tf-toggle-title h6'>
                                            <p className="toggle-content">{idx.benefits_text} </p>
                                        </Accordion>
                                    ))
                                }
                            </div> */}
                        </div>
                    </div>
                </div>
            </section >
        </div>
    );
}

export default Faqs;