import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap-accordion';
import flow from '../../assets/images/flow1.png'

function ProcessSteps() {
    const [dataFaq] = useState([
        {
            id: 1,
            title: 'Application',
            text: 'Projects apply on the ANTS Governance Portal.'
        },
        {
            id: 2,
            title: 'The ANTS Council',
            text: 'The DAO Council receives the selected projects and makes a final decision.'
        },
        {
            id: 3,
            title: 'IDO or NFT Sale',
            text: 'Launch your token or NFT collection on the official ANTS website.'
        },
        {
            id: 4,
            title: 'Due Diligence',
            text: 'The ANTS Research team reviews applications and moves selected projects to the next step.'
        },
        {
            id: 5,
            title: 'Pre-raise Support',
            text: 'Once approved, projects get the full ANTS team support leading up to the sale.'
        },
    ])
    return (
        <section className="tf-faq">
            <div className="tf-container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="tf-heading style-5">
                            <h4 className="heading">Streamlined application and launch process</h4>
                        </div>
                        <img
                            className='my-5'
                            src={flow}
                            alt="images"
                        />
                        {/* <div className="tf-accordion">
                            {
                                dataFaq.map(item => (
                                    <Accordion key={item.id} title={item.title} className='tf-toggle-title set-primary-color h6'>
                                        <p className="toggle-content">{item.text} </p>
                                    </Accordion>
                                ))
                            }
                        </div> */}
                    </div>
                </div>
            </div>
        </section >
    );
}

export default ProcessSteps;

