import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Web3 from 'web3';
import ABI from '../../utils/abi';
import { blockchainTxnAddress, contractAddress } from '../../utils/utils';
import { BigNumber } from 'bignumber.js';

const NgoModal = (props) => {
    const [amount, setamount] = useState()
    const [isLoading, setisLoading] = useState(false)
    const [data, setdata] = useState([])
    const [donationSendTemplate, setDonationSendTemplate] = useState([])
    const [donationReceiveTemplate, setDonationReceiveTemplate] = useState([])
    const [etherPrice, setEtherPrice] = useState(3892.90);

    const day = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    const date = month + "/" + day + "/" + year;
    const [monthlyDeadline, setmonthlyDeadline] = useState('')

    const [purchasedTickets, setPurchasedTickets] = useState([]);
     
    useEffect(() => {
        if (!props.show) {
            setamount('');
            setisLoading(false);
        }
    }, [props.show]);
    useEffect(() => {
        const AccountInfo = localStorage.getItem('antsInformation');
        const parsedInfo = JSON.parse(AccountInfo);
        if (parsedInfo) {
            fetchPurchasedTickets(parsedInfo)
        }
    }, [])

    const fetchPurchasedTickets = (parsedInfo) => {
        axios
            .get(`${global.backendUrl}/tickets/purchase/${parsedInfo?.uid}`)
            .then((res) => {
                console.log("Purchased tickets", res)
                setPurchasedTickets(res.data)
            })
            .catch((err) => console.log(err));
    }

    const handleContinue = async () => {
        console.log("purchasedTickets", purchasedTickets)
        console.log("amount", amount)
        console.log("props", props)
        const AccountInfo = localStorage.getItem('antsInformation');
        const parsedInfo = JSON.parse(AccountInfo);
        console.log("parsedInfo", parsedInfo)
        let tokensToAssign = "0"
        if (purchasedTickets.length > 0) {
            const ticketReturnValue = purchasedTickets[0].ticket_return
            const returnAmount = parseFloat(amount) * (parseFloat(ticketReturnValue) / 100);
            console.log("returnAmount", returnAmount)
            const weightage = purchasedTickets[0].ticket_weightage
            tokensToAssign = parseInt(Math.round(returnAmount / weightage));
            console.log("tokensToAssign", tokensToAssign)
        }
        if (props.wallet_address) {
            if (props.wallet_address == "") {
                toast.error("Project owner doesn't have any associated wallet!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return
            }
        } else {
            toast.error("Project owner doesn't have any associated wallet!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return
        }

        if (parseFloat(amount) <= 0) {
            toast.error("Enter amount greater than zero!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return
        }
  
        const userId = parsedInfo.uid;
        const affiliateId_user = parsedInfo.affiliate_id || null;  
        console.log("affiliate_id", affiliateId_user);
    
        let adjustedAmount = parseFloat(amount);
        let remainingValue = adjustedAmount;
    
        if (affiliateId_user) {
            const marginResponse = await axios.get(`${global.backendUrl}/api/affiliate_margin/get`);
            const affiliateMarginData = marginResponse.data.data;
            console.log("margin data", affiliateMarginData);
    
            if (affiliateMarginData && affiliateMarginData.margin) {
                const margin = parseFloat(affiliateMarginData.margin);
                const marginValue = (adjustedAmount * margin) / 100;
                remainingValue = adjustedAmount - marginValue;
                adjustedAmount = marginValue;
                console.log(`Adjusted Amount (after ${margin}% margin):`, adjustedAmount);
                console.log(`Remaining Value:`, remainingValue);
    
                const affiliateData = {
                    userId: userId,
                    affiliateId: affiliateId_user,
                    project_id: props.projectId,
                    value: adjustedAmount,
                    project_type: 'donate',
                };
    
                try {
                    await axios.post(`${global.backendUrl}/affiliates_donate/insert`, affiliateData);
                    console.log("Remaining value inserted successfully:", affiliateData);
                } catch (error) {
                    console.error("Error inserting remaining value:", error);
                }
            }
        }
    
            
        const dollarToEther = parseFloat(remainingValue) / etherPrice;

        if (!props.userId) { return showError("login again! There is an error") };
        if (!amount) { return showError("enter the amount") };

        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        axios.get(`${global.backendUrl}/walletassocaite`)
            .then(async (resAssociation) => {

                const filter = data?.filter((t) => (t.uid == props.userId) && (t.project_id == props.projectId))
                const web3 = new Web3(window.ethereum);
                await window.ethereum.enable();
                // Get the current account
                const accounts = await web3.eth.getAccounts();
                const account = accounts[0];
                let owner = account;
                console.log("dollarToEther", dollarToEther)
                console.log("dollarToEther", dollarToEther.toFixed(14))
                const dollarToEtherString = dollarToEther.toFixed(14);
                console.log("dollarToEtherString", dollarToEtherString)

                let weiValue = 0;
                weiValue = Web3.utils.toWei(dollarToEtherString, "ether");
                console.log("weiValue", weiValue)
                // return;
                // const weiEquivalentOfOneEther = Web3.utils.toBN("1000000000000000000"); // 10^18 wei

                // Convert to Wei
                // const etherValue = new BigNumber(dollarToEtherString);
                // const weiValue = web3.utils.toWei(etherValue.toFixed(), "ether");
                // return;
                let contract = new web3.eth.Contract(ABI, contractAddress);
                console.log(contract.methods);
                const gasPrice = await web3.eth.getGasPrice();
                console.log("gasPrice", gasPrice)
                try {
                    contract.methods
                        .donateProject(props.wallet_address, parsedInfo.wallet_address, tokensToAssign)
                        .send({
                            from: owner,
                            gas: 2000000,
                            gasPrice: gasPrice,
                            value: weiValue
                        })
                        .then(async function (result) {
                            console.log("Transaction:", result)
                            toast.success(`You donated ${amount} successfully`, {
                                onClose: () => props.onHide()
                            });
                            if (filter.length == 0) {
                                setisLoading(true)
                                let renewDate
                                if (props.donationType == 'monthly') { renewDate = monthlyDeadline } else { renewDate = null }

                                const formData = { project_id: props.projectId, uid: props.userId, donation_amount: remainingValue, donation_type: props.donationType, donation_date: date, donation_renew_date: renewDate }
                                axios.post(`${global.backendUrl}/donation/insert`, formData)
                                    .then((response) => {
                                        console.log("props.user_email", props.user_email)


                                        if (props.user_email && donationSendTemplate.length > 0) {
                                            let templateString = donationSendTemplate[0].email_template_content;
                                            let finalTemplate = templateString
                                                .replace("@donation_amount", "$" + remainingValue)
                                                .replace("@project_name", props.projectName)
                                                .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                            console.log("finalTemplate", finalTemplate)
                                            const formDataEmail = {
                                                user_email: props.user_email,
                                                subject: donationSendTemplate[0].email_template_subject,
                                                message: finalTemplate
                                            }
                                            axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                .then((res) => {
                                                    console.log("RES", res)
                                                    const AccountInfo = localStorage.getItem('antsInformation');
                                                    const parsedInfo = JSON.parse(AccountInfo);
                                                    console.log("parsedInfo", parsedInfo)
                                                    let email = parsedInfo?.email;
                                                    if (email && donationReceiveTemplate.length > 0) {
                                                        let templateStringReceive = donationReceiveTemplate[0].email_template_content;
                                                        let finalTemplateReceive = templateStringReceive
                                                            .replace("@donation_amount", "$" + remainingValue)
                                                            .replace("@project_name", props.projectName)
                                                            .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                                        console.log("finalTemplateReceive", finalTemplateReceive)
                                                        const formDataEmail = {
                                                            user_email: email,
                                                            subject: donationReceiveTemplate[0].email_template_subject,
                                                            message: finalTemplateReceive
                                                        }
                                                        axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                            .then((res) => {
                                                                toast.success(`Donation Sent Successfully`, {
                                                                    position: "top-right",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                                setamount("")
                                                                setisLoading(false)
                                                                props.onHide()
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            });
                                                    }

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        setisLoading(false)
                                    })
                            }
                            else {
                                setisLoading(true)
                                let renewDate
                                if (props.donationType == 'monthly') { renewDate = monthlyDeadline } else { renewDate = null }
                                const newAmount = parseInt(remainingValue) + parseInt(filter[0].donation_amount)
                                const formData = { donation_amount: newAmount, donation_type: props.donationType, donation_date: date, donation_renew_date: renewDate }
                                axios.put(`${global.backendUrl}/donation/update/${filter[0].donation_id}`, formData)
                                    .then((response) => {
                                        console.log("props.user_email", props.user_email)
                                        console.log("donationSendTemplate", donationSendTemplate)
                                        if (props.user_email && donationSendTemplate.length > 0) {
                                            let templateString = donationSendTemplate[0].email_template_content;
                                            let finalTemplate = templateString
                                                .replace("@donation_amount", "$" + remainingValue)
                                                .replace("@project_name", props.projectName)
                                                .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                            console.log("finalTemplate", finalTemplate)
                                            const formDataEmail = {
                                                user_email: props.user_email,
                                                subject: donationSendTemplate[0].email_template_subject,
                                                message: finalTemplate
                                            }
                                            axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                .then((res) => {
                                                    console.log("RES", res)
                                                    const AccountInfo = localStorage.getItem('antsInformation');
                                                    const parsedInfo = JSON.parse(AccountInfo);
                                                    console.log("parsedInfo", parsedInfo)
                                                    let email = parsedInfo?.email;
                                                    if (email && donationReceiveTemplate.length > 0) {
                                                        let templateStringReceive = donationReceiveTemplate[0].email_template_content;
                                                        let finalTemplateReceive = templateStringReceive
                                                            .replace("@donation_amount", "$" + remainingValue)
                                                            .replace("@project_name", props.projectName)
                                                            .replace("@txn_hash", blockchainTxnAddress + "/" + result.transactionHash);
                                                        console.log("finalTemplateReceive", finalTemplateReceive)
                                                        const formDataEmail = {
                                                            user_email: email,
                                                            subject: donationReceiveTemplate[0].email_template_subject,
                                                            message: finalTemplateReceive
                                                        }
                                                        axios.post(`${global.backendUrl}/sendEmail`, formDataEmail)
                                                            .then((res) => {
                                                                toast.success(`Donation Sent Successfully`, {
                                                                    position: "top-right",
                                                                    autoClose: 3000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                });
                                                                setamount("")
                                                                setisLoading(false)
                                                                props.onHide()
                                                            })
                                                            .catch((error) => {
                                                                console.log(error);
                                                            });
                                                    }

                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        setisLoading(false)
                                    })
                            }
                        })
                        .then(function (error) {
                            console.log(error);
                        })
                        .catch((error) => console.log(error));
                } catch (error) {
                    console.error('Error fetching data:', error);
                    return { data: {} };  
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const fetchData = () => {
        axios.get(`${global.backendUrl}/donation/get`)
            .then((res) => {
                setdata(res.data)
            })
            .catch((error) => {
                console.log(error);
            });
        axios.get(`${global.backendUrl}/email_templates`)
            .then((res) => {
                // console.log("RES", res)
                const donationSentTemplates = res.data.filter((item) => item.email_template_type === "Donation Send")
                setDonationSendTemplate(donationSentTemplates)

                const donationReceiveTemplates = res.data.filter((item) => item.email_template_type === "Donation Received")
                setDonationReceiveTemplate(donationReceiveTemplates)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    function getDollarValues() {
        axios
            .get(
                `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc  &per_page=100&page=1&sparkline=false`
            )
            .then((response) => {
                setEtherPrice(response.data[1].current_price);
            })
            .catch((error) => console.log(error));
    }
    useEffect(() => {
        fetchData();
        getDollarValues()
    }, [props]);

    const handleAmountChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (inputValue < 0) {
            setamount(0);
        } else {
            setamount(inputValue);
        }
    };

    useEffect(() => {
        let nextMonth, nextYear;
        if (month === 11) {
            nextMonth = 1;
            nextYear = year + 1;
        } else {
            nextMonth = month + 1;
            nextYear = year;
        }
        const monthdead = nextMonth + "/" + day + "/" + nextYear;
        setmonthlyDeadline(monthdead);
    }, [])


    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body space-y-20 pd-40">
                <h3>Donate Here</h3>
                <input type="number" value={amount} onChange={handleAmountChange} className="form-control mb-5" placeholder="Enter amount here in dollar" />
                <button className="button-popup w-100" disabled={isLoading} onClick={handleContinue}>Continue</button>
            </div>
        </Modal>

    );
};
// }
export default NgoModal;
