import React, { useState, useEffect } from 'react';
import dataCategory from '../assets/fake-data/data-category';
import dataCollection from '../assets/fake-data/data-collection';
import dataCreate from '../assets/fake-data/data-create';
import dataExplore from '../assets/fake-data/data-explore';
import dataHotpick from '../assets/fake-data/data-hotpick';
import dataLiveaution from '../assets/fake-data/data-liveaution';
import dataSeller from '../assets/fake-data/data-topseller';
import Banner01 from '../components/banner/Banner01';
import Category from '../components/category/Category';
import Collection from '../components/collection/Collection';
import Create from '../components/create/Create';
import Explore from '../components/explore/Explore';
import HotPick from '../components/hotpick/HotPick';
import LiveAutions from '../components/liveautions/LiveAuctions';

import TopSeller from '../components/topseller/TopSeller';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import { WebsiteUrl } from '../App';
import TopSeller5 from '../components/topseller/TopSeller5';

function Home01(props) {
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);
  const [data4, setdata4] = useState([]);
  const [data5, setdata5] = useState([]);
  const [data6, setdata6] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [titles, settitles] = useState([]);

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/homePageSco/get`)
      .then((response) => {
        setdata(response.data[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });

    axios
      .get(`${global.backendUrl}/antspartnerstitle`)
      .then((res) => {
        console.log(res.data);

        settitles(res.data);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${global.backendUrl}/ourantspartners`)
      .then((res) => setdata2(res.data))
      .catch((error) => console.log(error));

    axios
      .get(`${global.backendUrl}/project/get`)
      .then((res) => setdata3(res.data))
      .catch((error) => console.log(error));

    axios
      .get(`${global.backendUrl}/stats/get`)
      .then((res) => setdata4(res.data))
      .catch((error) => console.log(error));

    axios
      .get(`${global.backendUrl}/projectCate/get`)
      .then((res) => {
        setdata5(res.data)
      })
      .catch((error) => console.log(error));

    axios
      .get(`${global.backendUrl}/signup/get`)
      .then((res) => setdata6(res.data))
      .catch((error) => console.log(error));
  }, []);

  if (isLoading) {
    return null; // or a loading spinner
  }


  return (
    <div className="home-1">
      {data && (
        <Helmet>
          <title>{data?.meta_title}</title>

          <meta name="description" content={data?.meta_description} />
          <meta name="keywords" content={JSON.parse(data?.meta_keywords)} />
          <meta name="robots" content="index,follow" />
          <meta property="og:title" content={data?.meta_title} />
          <meta property="og:description" content={data?.meta_description} />
          <meta
            property="og:image"
            content={`${global.imageURL}/nodeassets/${data?.meta_img}`}
          />
          {/* <meta property="og:url" content={`${WebsiteUrl}`} /> */}
          {/* <meta
                            property="og:url"
                            content={`${WebsiteUrl}`}
                        /> */}
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content={data?.meta_title} />
          <meta name="twitter:title" content={data?.meta_title} />
          <meta
            name="twitter:description"
            content={data?.meta_description}
          />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="favicon" href="/favicon.ico" />
          <meta
            name="twitter:image"
            content={`${global.imageURL}/nodeassets/${data?.meta_img}`}
          />
        </Helmet>
      )}
      <div id="page">
        <Banner01 />

        <Create data={data4} />

        <Category data={data5} />

        <LiveAutions data={data3} />

        <HotPick data={data5} data2={data3} data3={data6} />

        <Collection data={data6} />

        <TopSeller data={data5} data2={data3} data3={data6} />

        <Explore data={data5} data2={data3} data3={data6} />

        <TopSeller5 data={data2} titles={titles} />
      </div>
    </div>
  );
}

export default Home01;
