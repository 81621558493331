import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import icon1 from '../../assets/images/svg/icon-create-5.svg';
import icon2 from '../../assets/images/svg/icon-create-6.svg';
import icon3 from '../../assets/images/svg/icon-create-3.svg';
import icon4 from '../../assets/images/svg/icon-create-7.svg';
import icon5 from '../../assets/images/svg/icon-create-8.svg';
import star from '../../assets/images/slider/slider-8.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { truncate } from 'lodash';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import ABI from '../../utils/abi';
import { contractAddress } from '../../utils/utils';

function Tickets() {
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const [userData, setUserData] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null); // Hold a single expanded item ID
    const [etherPrice, setEtherPrice] = useState(0);
    const [purchasedTickets, setPurchasedTickets] = useState([]);

    function getDollarValues() {
        axios
            .get(
                `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc  &per_page=100&page=1&sparkline=false`
            )
            .then((response) => {
                setEtherPrice(response.data[1].current_price);
            })
            .catch((error) => console.log(error));
    }

    const toggleExpand = (ticketId) => {
        // Toggle between expanding the clicked item or collapsing it
        setExpandedItem((prev) => (prev === ticketId ? null : ticketId));
    };

    useEffect(() => {
        const AccountInfo = localStorage.getItem('antsInformation');
        const parsedInfo = JSON.parse(AccountInfo);
        setUserData(parsedInfo);
        fetchData();
        getDollarValues();
        if (parsedInfo) {
            fetchPurchasedTickets(parsedInfo);
        }
    }, []);

    const fetchData = () => {
        axios
            .get(`${global.backendUrl}/tickets`)
            .then((res) => {
                setTickets(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    console.log('tickets id',tickets.map((id)=>id.ticket_id))
    const fetchPurchasedTickets = (parsedInfo) => {
        axios
            .get(`${global.backendUrl}/tickets/purchase/${parsedInfo?.uid}`)
            .then((res) => {
                console.log('Purchased tickets', res);
                setPurchasedTickets(res.data);
            })
            .catch((err) => console.log(err));
    };

    const purchaseTicket = async (ticketId, weightage, ticketPrice, ticketReturnValue) => {
        console.log('purchasedTickets', purchasedTickets);
        if (purchasedTickets.length > 0) {
            toast.info("You've already purchased a ticket.");
            return;
        }
        const returnEth = parseFloat(ticketPrice) * (parseFloat(ticketReturnValue) / 100);
        const EtherToDollars = parseFloat(returnEth) * etherPrice;
        const tokensToAssign = parseInt(Math.round(EtherToDollars / weightage));
        console.log('tokensToAssign', tokensToAssign);

        if (!userData) {
            navigate('/login');
            return;
        }
        axios.get(`${global.backendUrl}/walletassocaite`).then(async (resAssociation) => {
            if (resAssociation.data.length < 1) {
                toast.error('Enter amount greater than zero!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }
            let wallets = [],
                percentages = [];
            resAssociation.data.forEach((element) => {
                wallets.push(element.wallet_address);
                percentages.push(parseFloat(element.wallet_percentage));
            });

            const web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0];
            let owner = account;
            const EtherToDollarsString = EtherToDollars.toFixed(14);

            let weiValue = 0;
            weiValue = Web3.utils.toWei(ticketPrice, 'ether');

            let contract = new web3.eth.Contract(ABI, contractAddress);
            const gasPrice = await web3.eth.getGasPrice();

            contract.methods
                .purchaseTicket(userData?.wallet_address, tokensToAssign, wallets, percentages)
                .send({
                    from: owner,
                    gas: 2000000,
                    gasPrice: gasPrice,
                    value: weiValue,
                })
                .then(async function (result) {
                    const formData = {
                        user_id: userData.uid,
                        ticket_id: ticketId,
                        ticket_purchase_weightage: tokensToAssign,
                    };
                    axios
                        .post(`${global.backendUrl}/tickets/purchase`, formData)
                        .then((res) => {
                            toast.success('Ticket purchased successfully');
                            navigate('/dashboard');
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => console.log(error));
        });
    };

    return (
        <section className="tf-help-center">
            <div className="tf-container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12">
                        <div className="tf-heading style-5">
                            <h4 className="heading">Be a part of Governance</h4>
                        </div>
                        <div className="tf-create-wrap">
                            {tickets.map((item) => {
                                return(
                                    <div className="col-md-4">

                                <div key={item.ticket_id} className={`tf-create style-2 ${expandedItem === item.ticket_id ? 'expanded' : ''}`}
                                >
                                    <h6 className="title">{item.ticket_name}</h6>
                                    <ul className="key-value-list">
                                        <li>
                                            <span className="key">{'Ticket Price'}</span>
                                            <span className="value">
                                                <b>{item.ticket_price} ETH</b>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="key">{'Mintback on every transaction'}</span>
                                            <span className="value">
                                                <b>{item.ticket_return}%</b>
                                            </span>
                                        </li>
                                        <li>
                                            <span className="key">{'Instant Mintback'}</span>
                                            <span className="value">
                                                <b>${item.ticket_weightage} Per Token</b>
                                            </span>
                                        </li>
                                    </ul>
                                    <p className="content" style={{ textAlign: 'start' }}>
                                        {expandedItem === item.ticket_id ? (
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item.ticket_description }}
                                            />
                                        ) : (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: truncate(item.ticket_description, { length: 60 }),
                                                }}
                                            />
                                        )}
                                    </p>
                                    <div className="button">
                                        <div className="tf-button" onClick={() => toggleExpand(item.ticket_id)}>
                                            {expandedItem === item.ticket_id ? 'View less' : 'View more'}
                                            <i className="fal fa-long-arrow-right"></i>
                                        </div>
                                        <div
                                            className="tf-button button-background-blue ml-2"
                                            onClick={() => {
                                                purchaseTicket(
                                                    item.ticket_id,
                                                    item.ticket_weightage,
                                                    item.ticket_price,
                                                    item.ticket_return
                                                );
                                            }}
                                        >
                                            Purchase
                                        </div>
                                    </div>
                                </div>
                                </div>

                                )
})}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tickets;
