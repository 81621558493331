import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo_dark.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './styles.scss';

function Footer(props) {
    const navigate = useNavigate();
      const location = useLocation();
    
  const [data, setdata] = useState([]);
  const [email, setemail] = useState('');
  const [socialLinks, setSocialLinks] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const [footerImage, setfooterImage] = useState('');
  const [footerDescription, setFooterDescription] = useState('');
  const currentYear = new Date().getFullYear();
  const [productLink] = useState([
    {
      title: 'Spot',
      path: '/spot',
    },
    {
      title: 'Inverse Perpetual',
      path: '#',
    },
    {
      title: 'USDT Perpetual',
      path: '#',
    },
    {
      title: 'Exchange',
      path: '#',
    },
    {
      title: 'Launchpad',
      path: '#',
    },
    {
      title: 'Binance Pay',
      path: '#',
    },
  ]);
  const [servicesLink] = useState([
    {
      title: 'Buy Crypto',
      path: '#',
    },
    {
      title: 'Markets',
      path: '#',
    },
    {
      title: 'Tranding Fee',
      path: '#',
    },
    {
      title: 'Affiliate Program',
      path: '#',
    },
    {
      title: 'Referral Program',
      path: '#',
    },
    {
      title: 'API',
      path: '#',
    },
  ]);

  const [listSocial] = useState([
    {
      icon: 'icon-facebook-f',
      path: '#',
    },
    {
      icon: 'icon-instagram',
      path: '#',
    },
    {
      icon: 'icon-youtube',
      path: '#',
    },
    {
      icon: 'icon-twitter',
      path: '#',
    },
  ]);

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleJoin = () => {
    if (!email) {
      return toast.error(`Please Enter The Email`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      return toast.error(`Please enter a valid email address.`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const filteredEmail = data.filter((t) => t.email === email);

    if (filteredEmail.length == 0) {
      setisLoading(true);
      const formData = { email };
      axios
        .post(`${global.backendUrl}/subscriber/insert`, formData)
        .then((response) => {
          toast.success(`Your are Joined Successfully !`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setisLoading(false);
          setemail('');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error(`This Email Already Exists`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/subscriber/get`)
      .then((response) => {
        setdata(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${global.backendUrl}/antsfooter`)
      .then((response) => {
        console.log("Footer Links", response)
        setSocialLinks(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/antsbrand`)
      .then((response) => {
        setfooterImage(response.data[0].antsbrand_header_img);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${global.backendUrl}/footer_content`)
      .then((response) => {
        setFooterDescription(response.data[0].footer_description);
      })
      .catch((err) => {
        console.log(err);
      });

    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const handleLogoClick = (e) => {
    if (location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      navigate('/');
    }
  };
  return (
    <footer className="footer">
      <div className="tf-container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-3">
            <div className="widget widget-infor">
              <div className="logo" onClick={handleLogoClick}>
                {/* <img id="logo_footer" className='logo-dark' src={logodark} alt="ANTS" /> */}
                {/* <img id="logo_footer" className='logo-light' src={logo} alt="ANTS" /> */}
                <img
                  id="logo_footer"
                  className="logo-dark"
                  src={`${global.imageURL}/nodeassets/${footerImage}`}
                  alt="ANTS"
                />
                <img
                  id="logo_footer"
                  style={{ filter: 'invert(1)' }}
                  className="logo-light"
                  src={`${global.imageURL}/nodeassets/${footerImage}`}
                  alt="ANTS"
                />
              </div>
              <p className="content" style={{width:'100%'}}>
                {footerDescription}
              </p>
              <ul className="social-item ">
                {socialLinks.map((val, ind) => {
                  return (
                    <li key={ind}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${val.footer_link}`}
                      >
                        {
                          val.footer_link_name === "Facebook" ?
                            <i className="fab fa-facebook"></i>
                            : val.footer_link_name === "Twitter" ?
                              <i className="fab fa-twitter"></i>
                              : val.footer_link_name === "Instagram" ?
                                <i class="fa-brands fa-instagram"></i>
                                : val.footer_link_name === "Discord" ?
                                  <i className="icon-fl-vt"></i>
                                  : val.footer_link_name === "Discord" ?
                                    <i className="fab fa-github"></i>
                                    :
                                    <p>NA</p>
                        }
                      </a>
                    </li>
                  );
                })}
              </ul>
              <ul hidden className="social-item">
                <li>
                  <a
                    href={socialLinks[2]?.footer_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={socialLinks[1]?.footer_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href={socialLinks[3]?.footer_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* <i className="fab fa-telegram-plane"></i> */}
                    <i class="fa-brands fa-instagram"></i>
                    {/* <i className="fa-brands fa-square-instagram"></i> */}
                  </a>
                </li>
                <li>
                  <a
                    href={socialLinks[0]?.footer_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* <i class="fa-brands fa-square-reddit"></i> */}
                    <i class="fa-brands fa-reddit-alien"></i>
                    {/* <i className="fab fa-youtube"></i> */}
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://twitter.com/AntsProtocol"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-fl-tik-tok-2"></i>
                  </a>
                </li> */}
                <li>
                  <a
                    href={socialLinks[4]?.footer_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="icon-fl-vt"></i>
                  </a>
                </li>
              </ul>
              <p className="copy-right">
                Copyright © {currentYear} ANTS PROTOCOL. All Rights Reserved.
              </p>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 col-md-5">
            <div className="widget widget-menu">
              <div className="menu menu-1">
                <h6 className="widget-title">Discover & Invest</h6>
                <ul>
                  <li>
                    <Link to="/projects">Explore</Link>
                  </li>
                  <li>
                    <Link to="/ngo-projects">NGO</Link>
                  </li>
                  <li>
                    {/* /projects?project_type=Profitable Business */}
                    <Link to="/profitable-projects">Profit-Driven</Link>
                  </li>
                  <li>
                    <Link to="/pools">Pools</Link>
                  </li>
                  <li>
                    <Link to="/governance">Tickets</Link>
                  </li>
                  <li>
                    <Link to="/governance">Be a part of governance</Link>
                  </li>
                </ul>
              </div>
              <div className="menu menu-3">
                <h6 className="widget-title">About</h6>
                <ul>
                  <li>
                    <Link to="/howitworks">How it works</Link>
                  </li>
                  <li>
                    <Link to="/benefits">Benefits</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                </ul>
              </div>
              <div className="menu menu-4">
                <h6 className="widget-title">My account</h6>
                <ul>
                  <li>
                    <Link to="/dashboard">Profile</Link>
                  </li>
                  <li>
                    <Link to="/dashboard">My wallet</Link>
                  </li>
                  <li>
                    <Link to="/create">Create</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4">
            <div className="widget widget-subcribe">
              <h6 className="widget-title">Subscribe Us</h6>
              <p className="content">
                Signup for our newsletter to get the latest news in your inbox.
              </p>
              <div className='d-flex' id="subscribe-form">
                <input
                  type="email"
                  placeholder="Info@yourgmail.com"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  required=""
                  id="subscribe-email"
                />
                <button
                  className="tf-button"
                  id="subscribe-button"
                  disabled={isLoading}
                  onClick={handleJoin}
                >
                  <i className="icon-fl-send"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </footer>
  );
}

export default Footer;