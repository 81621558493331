import { useEffect, useState } from "react";
import { getStaking } from "../api/Api";

export const usePools=()=>{
    const [dataAPI, setDataAPI] = useState([]);

    const getData = async () => {
      const res = await getStaking();
      setDataAPI([...res.data]);
    };

    useEffect(() => {
        
        getData()
      }, []);
      return {
        dataAPI,
        getData
      }
}